import React from 'react'

import Layout from '../components/layout'

const PrivacyPage = ({ pageContext: { language, originalPath } }) => {
  const Privacy =
    language === 'nl'
      ? require('../components/policy-nl').default
      : require('../components/policy-fr').default
  return (
    <Layout language={language} path={originalPath}>
      <div>
        <Privacy />
      </div>
    </Layout>
  )
}

export default PrivacyPage
