import React from 'react'

export default () => (
  <>
    <h2>Nous avons quelques principes simples</h2>
    <ol>
      <li>
        Nous collectons uniquement vos informations personnelles{' '}
        <strong>lorsque nous en avons besoin</strong>, et uniquement
        <span>&nbsp;</span>
        <strong>ce dont nous avons besoin</strong>
        <span>&nbsp;</span>pour vous offrir nos services.
      </li>
      <li>
        Nous ne <strong>Partager</strong>
        <span>&nbsp;</span>vos informations personnelles avec qui que ce soit
        sauf avec offrir et développer nos services, respecter la loi ou les
        droits, propriété ou de notre sécurité, nos utilisateurs ou le public.
      </li>
      <li>
        Nous ne <strong>magasin</strong>
        <span>&nbsp;</span>vos informations personnelles, sauf si nécessaire le
        fonctionnement continu de nos services.
      </li>
      <li>
        Nous vous donnons <strong>contrôle</strong>
        <span>&nbsp;</span>sur la manière dont vos informations personnelles
        sont utilisées, y compris ce qui a été rendu visible au public, vu à
        travers Les moteurs de recherche, gardés privés et supprimés. <br />
        <br />
      </li>
    </ol>
    <h2>Quelles informations recueillons-nous?</h2>
    <h3>Informations que vous nous communiquez</h3>
    <p>
      Lorsque vous utilisez nos services, nous pouvons vous demander des
      informations, par exemple une adresse e-mail à enregistrer avec votre
      compte, à participer à un concours ou répondre à votre demande
      d'assistance. <br />
      Vous pouvez toujours s'abstenir de fournir ces informations, bien que cela
      soit possible est de vous empêcher d'utiliser complètement nos services.
    </p>
    <h3>
      Les informations que nous obtenons de votre utilisation de nos services
    </h3>
    <p>
      Nous recueillons des informations sur les services que vous utilisez,
      quand et comment vous utilisez-les. <br />
      Ces informations incluent des informations sur le périphérique (par
      exemple, le modèle, le fonctionnement système, ID de périphérique tels que
      IDFA et UDID), informations de journal (par exemple, et comment vous avez
      utilisé notre service, l'appareil utilisé, la langue de préférence, etc.)
      et des données d’analyse (par exemple, cookies, balises, tags et scripts).
    </p>
    <h2>Comment utilisons-nous ces informations?</h2>
    <p>
      Nous utilisons toutes les informations dont nous disposons pour nous
      aider, maintenir, protéger et améliorer nos services, en développer et en
      développer de nouveaux protégez-nous et nos utilisateurs. <br />
      Nous vous demanderons votre permission avant d’obtenir des informations
      sur un certain autre que ceux énoncés dans la présente politique de
      confidentialité.
    </p>
    <h3>Gérer et améliorer nos services</h3>
    <p>
      Nous pouvons fournir nos services, personnaliser et créer du contenu
      suggestions pour vous en utilisant les informations fournies. <br />
      Nous utilisons les informations collectées pour comprendre comment vous
      utilisez et qui vous permet de gérer nos services à votre expérience
      utilisateur et la globale qualité d’améliorer Nos services.
    </p>
    <h3>Pour vous contacter</h3>
    <p>
      Lorsque vous nous contactez, nous garderons trace de votre communication
      avec vous aider à identifier et à résoudre les problèmes que vous et
      d'autres utilisateurs peut rencontrer. <br />
      Si vous avez fourni votre adresse e-mail, nous pouvons parfois vous
      envoyer un e-mail. envoyer un e-mail pour vous informer sur les offres
      spéciales, les nouvelles fonctions, demandez vos commentaires ou
      simplement rester informé de ce que il nous arrive et nos produits. <br />
      Vous pouvez choisir notre arrêter les e-mails à tout moment en se
      désabonnant via un lien avec l'un d'entre eux cliquez sur n'importe quel
      email que nous avons envoyé ou vous pouvez nous contacter enregistrer par
      courrier (voir ci-dessous).
    </p>
    <h3>Afficher les annonces pertinentes dans nos applications gratuites</h3>
    <p>
      Nous et nos partenaires publicitaires externes pouvons proposer des
      publicités lorsque vous utilisez nos services. <br />
      Nous ne fournissons pas d'informations personnelles aux annonceurs. <br />
      Pour proposer des annonces qui vous intéressent, nous et notre tiers Les
      partenaires publicitaires peuvent utiliser les informations recueillies
      auprès de vous et de votre visites à nos et autres services.
    </p>
    <h2>Comment puis-je gérer les informations qui me concernent?</h2>
    <p>
      Contactez-nous pour supprimer vos données{' '}
      <a href="mailto:support@itheorie.ch" title="Contact">
        support@itheorie.ch
      </a>
    </p>
    <h2>Conservation des données</h2>
    <p>
      Nous ne conservons vos informations personnelles que le temps nécessaire
      ou affaires légitimes ou à des fins légales Nous pouvons aussi et
      conserver des informations non personnelles, y compris des informations
      ont été anonymes, agrégés ou anonymes. <br />
      Nous concevons nos systèmes de manière à protéger les informations contre
      les attaques involontaires. ou destruction malveillante. Pour cette
      raison, lorsque vous supprimez ou mettez à jour votre compte ou des
      informations de nos services, nous ne pouvons pas
      <br />
      Nous concevons nos systèmes de manière à protéger les informations contre
      les attaques involontaires. ou destruction malveillante. Pour cette
      raison, lorsque vous supprimez ou mettez à jour votre compte ou des
      informations de nos services, nous ne pouvons pas immédiatement supprimer
      les copies restantes de nos serveurs actifs et éventuellement certains ne
      supprimez pas les informations de nos systèmes de sauvegarde.
    </p>
    <h2>Comment partageons-nous ces informations?</h2>
    <p>
      Nous ne partageons pas vos informations personnelles à moins que l’un des
      éléments suivants les conditions s'appliquent:
    </p>
    <h3>Avec votre permission</h3>
    <p>
      Nous ne partagerons les informations personnelles qu'avec des entreprises,
      des organisations ou des personnes en dehors de notre société lorsque nous
      avons votre permission de le faire fais le.
    </p>
    <h3>Pour le traitement externe</h3>
    <p>
      Nous proposons des informations personnelles et non personnelles à nos
      partenaires, organisations de confiance, fournisseurs et autres
      organisations affiliées Traiter en notre nom, conformément à nos
      instructions, Confidentialité Politiques et autres règles de
      confidentialité, de sécurité ou autres applicables exigences que nous
      jugeons nécessaires. <br />
      Certains de ces contractants et organisations affiliées peuvent être
      trouvé en dehors de votre pays d'origine et en utilisant nos services,
      Vous consentez au transfert de vos informations. <br />
      De temps en temps, nous pouvons recevoir des informations non
      personnellement identifiables publication totale, par exemple en publiant
      un rapport sur tendances dans l'utilisation de son site web.
    </p>
    <h3>Répondre aux demandes juridiques et autres et éviter les dommages</h3>
    <p>
      Nous partagerons des informations personnelles avec des entreprises, des
      organisations ou des personnes extérieures à notre entreprise si nous y
      croyons de bonne foi besoin de: <br />
      1. se conformer à la loi. <br />
      2. Maintenir nos conditions d'utilisation. <br />
      3. détecter, prévenir ou autrement lutter contre la fraude, la sécurité ou
      problèmes techniques. <br />
      4. Protéger et prévenir les dommages pour nous, nos utilisateurs et eux
      public.
    </p>
    <h2>Comment sécurisons-nous vos informations?</h2>
    <p>
      Nous suivons les normes généralement acceptées du secteur pour nous
      protéger contre l'accès non autorisé, l'utilisation, la modification ou la
      destruction de informations personnelles. <br />
      Si nous savons ou avons des raisons de savoir qu'il y a eu violation de la
      protection du système par une partie non autorisée de votre données
      utilisateur où ces données sont ou sont susceptibles d'être utilisé à des
      fins non autorisées, nous en informerons immédiatement afin que vous
      puissiez prendre les mesures appropriées.
    </p>
    <h2>
      Comment pouvons-nous vous informer des modifications apportées à nos
      politiques?
    </h2>
    <p>
      Nous nous réservons le droit de modifier cette politique de
      confidentialité à tout moment. Nous changeons la façon dont nous changeons
      vos informations personnelles utiliser, essentiellement vous informer à
      l'avance par e-mail.
    </p>
    <h2>Contactez-nous</h2>
    <p>
      Si vous avez des questions sur ce document, les procédures de nos sites
      sont ou applications, s'il vous plaît contactez-nous: <br />
      <br />
      European Computer Services SPRLU <br />
      Rue Emile Feron 168 1060 <br />
      Saint-Gilles Belgique
    </p>
    <p>Ce document a été mis à jour le 12 décembre 2018</p>
  </>
)
