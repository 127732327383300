import React from 'react'

export default () => (
  <>
    <h2>We hebben enkele eenvoudige principes</h2>
    <ol>
      <li>
        We verzamelen alleen uw persoonlijke gegevens{' '}
        <strong>wanneer we het nodig hebben</strong>, en alleen{' '}
        <strong>wat we nodig hebben</strong> om u onze services te bieden.
      </li>
      <li>
        We doen niet <strong>Delen</strong> uw persoonlijke gegevens met
        iedereen behalve met onze services aanbieden en ontwikkelen, de wet
        naleven of de rechten, eigendommen of onze veiligheid, onze gebruikers
        of het publiek.
      </li>
      <li>
        We doen niet <strong>winkel</strong> uw persoonlijke gegevens, tenzij
        dat nodig is voor de voortdurende werking van onze diensten.
      </li>
      <li>
        We geven u<strong>control</strong> over hoe uw persoonlijke gegevens
        worden gebruikt, inclusief wat zichtbaar is gemaakt voor het publiek,
        gezien door zoekmachines, privé gehouden en verwijderd. <br />
        <br />
      </li>
    </ol>
    <h2>Welke informatie verzamelen we?</h2>
    <h3>Informatie die u ons geeft</h3>
    <p>
      Wanneer u onze services gebruikt, kunnen we u om informatie vragen,
      bijvoorbeeld een e-mailadres om op te slaan met uw account, deel te nemen
      aan een wedstrijd of beantwoord uw ondersteuningsverzoek. <br />U kunt
      altijd afzien van het verstrekken van deze informatie, hoewel dat mogelijk
      is voorkomen dat u onze diensten volledig gebruikt.
    </p>
    <h3>Informatie die we verkrijgen van uw gebruik van onze services</h3>
    <p>
      We verzamelen informatie over de services die u gebruikt, wanneer en hoe u
      gebruik ze. <br />
      Deze informatie omvat apparaatinformatie (bijvoorbeeld model, werking
      systeem, apparaat-ID's zoals IDFA en UDID), loginformatie (bijv. wanneer
      en hoe u onze service gebruikte, het gebruikte apparaat, taalvoorkeur,
      enz.) en analysegegevens (bijvoorbeeld als cookies, bakens, tags en
      scripts).
    </p>
    <h2>Hoe gebruiken we deze informatie?</h2>
    <p>
      We gebruiken alle informatie die we hebben om ons te helpen bieden,
      onderhouden, onze diensten beschermen en verbeteren, nieuwe ontwikkelen en
      ons beschermen en onze gebruikers. <br />
      We zullen om uw toestemming vragen voordat we informatie voor een bepaald
      doel gebruiken andere dan die zijn uiteengezet in dit privacybeleid.
    </p>
    <h3>Onze services beheren en verbeteren</h3>
    <p>
      We kunnen onze services leveren, content personaliseren en maken
      suggesties voor u met behulp van de verstrekte informatie. <br />
      We gebruiken verzamelde informatie om te begrijpen hoe u gebruikt en
      waarmee u omgaat onze diensten om uw gebruikerservaring en de algehele
      kwaliteit van te verbeteren Onze diensten.
    </p>
    <h3>Om contact met u op te nemen</h3>
    <p>
      Wanneer u contact met ons opneemt, houden we uw communicatie bij om u te
      helpen identificeren en oplossen van problemen die u en andere gebruikers
      kunnen tegenkomen. <br />
      Als u uw e-mailadres hebt opgegeven, kunnen we u af en toe een e-mail
      sturen e-mail om u te vertellen over speciale aanbiedingen, nieuwe
      functies, vraag uw feedback geven of gewoon op de hoogte blijven van wat
      er met ons gebeurt en onze producten. <br />U kunt ervoor kiezen om onze
      e-mail op elk moment te stoppen door u af te melden via een link met één
      klik in elke e-mail die door ons wordt verzonden of kunt u contact met ons
      opnemen via mail (zie hieronder).
    </p>
    <h3>Relevante advertenties weergeven in onze gratis apps</h3>
    <p>
      Wij en onze externe advertentiepartners kunnen advertenties aanbieden
      wanneer u onze diensten gebruikt. <br />
      We verstrekken geen persoonlijke informatie aan adverteerders. <br />
      Om advertenties te bieden die u interesseren, wij en onze derde partij
      reclamepartners kunnen informatie gebruiken die is verzameld van u en uw
      bezoeken aan onze en andere diensten.
    </p>
    <h2>Hoe kan ik informatie over mij beheren?</h2>
    <p>
      Neem contact met ons op om uw gegevens te verwijderen{' '}
      <a href="mailto:support@itheorie.ch" title="Contact">
        support@itheorie.ch
      </a>
    </p>
    <h2>Dataretentie</h2>
    <p>
      We bewaren uw persoonlijke gegevens alleen voor zolang als nodig of om
      legitieme zakelijke of juridische doeleinden vervullen. We kunnen ook en
      behouden gebruik niet-persoonlijke informatie, inclusief informatie die is
      geweest geanonimiseerd, geaggregeerd of geanonimiseerd. <br />
      We ontwerpen onze systemen om informatie te beschermen tegen onopzettelijk
      of kwaadwillende vernietiging. Hierdoor, wanneer u uw verwijdert of update
      account of informatie van onze services, we kunnen dat niet
      <br />
      We ontwerpen onze systemen om informatie te beschermen tegen onopzettelijk
      of kwaadwillende vernietiging. Hierdoor, wanneer u uw verwijdert of update
      account of informatie van onze services, kunnen we niet onmiddellijk
      verwijderen resterende kopieën van onze actieve servers en mogelijk enkele
      niet verwijderen informatie van onze back-upsystemen.
    </p>
    <h2>Hoe delen we deze informatie?</h2>
    <p>
      We delen uw persoonlijke gegevens niet tenzij een van de volgende
      voorwaarden van toepassing:
    </p>
    <h3>Met uw toestemming</h3>
    <p>
      We zullen alleen persoonlijke informatie delen met bedrijven, organisaties
      of personen buiten ons bedrijf wanneer we uw toestemming hebben om dit te
      doen.
    </p>
    <h3>Voor externe verwerking</h3>
    <p>
      Wij bieden persoonlijke en niet-persoonlijke informatie aan onze partners,
      vertrouwde organisaties, verkopers en andere aangesloten organisaties het
      in onze naam verwerken, in overeenstemming met onze instructies, Privacy
      Beleid en andere toepasselijke vertrouwelijkheid, beveiliging of andere
      vereisten die wij nodig achten. <br />
      Sommige van deze aannemers en aangesloten organisaties kunnen worden
      gevonden buiten uw eigen land en door gebruik te maken van onze diensten,
      stemt u ermee in de overdracht van uw informatie aan hen. <br />
      Van tijd tot tijd kunnen we niet-persoonlijk identificeerbare informatie
      vrijgeven in totaal, bijvoorbeeld door het publiceren van een rapport over
      trends in de gebruik van zijn website.
    </p>
    <h3>Reageren op juridische en andere verzoeken en schade voorkomen</h3>
    <p>
      We zullen persoonlijke informatie delen met bedrijven, organisaties of
      personen buiten ons bedrijf als we dat in goed vertrouwen geloven dit is
      nodig om: <br />
      1. voldoen aan de wet. <br />
      2. onze servicevoorwaarden handhaven. <br />
      3. detecteren, voorkomen of anderszins adres fraude, beveiliging of
      technische kwesties. <br />
      4. beschermen tegen en voorkomen schade voor ons, onze gebruikers en het
      publiek.
    </p>
    <h2>Hoe beveiligen we uw informatie?</h2>
    <p>
      We volgen algemeen aanvaarde industriestandaarden om te beschermen tegen
      de ongeoorloofde toegang, gebruik, wijziging of vernietiging van
      persoonlijke gegevens informatie. <br />
      Als we weten of hebben reden om te weten van een schending van de
      systeembeveiliging door een ongeautoriseerde partij van uw
      gebruikersgegevens waar die gegevens zijn of zijn waarschijnlijk worden
      gebruikt voor een ongeoorloofd doel, zullen we onmiddellijk op de hoogte
      brengen zodat u passende stappen kunt ondernemen.
    </p>
    <h2>Hoe kunnen we u op de hoogte stellen van wijzigingen in ons beleid?</h2>
    <p>
      We behouden ons het recht voor om dit Privacybeleid op elk gewenst moment
      te wijzigen we veranderen de manier waarop we uw persoonlijke gegevens
      gebruiken, wezenlijk u vooraf op de hoogte stellen via e-mail.
    </p>
    <h2>Contact met ons opnemen</h2>
    <p>
      Als u vragen over dit document heeft, zijn de procedures van onze sites of
      apps, neem dan contact met ons op: <br />
      <br />
      European Computer Services SPRLU <br />
      Rue Emile Feron 168 1060 <br />
      Saint-Gilles België
    </p>
    <p>Dit document is voor het laatst bijgewerkt op 12 december 2018</p>
  </>
)
